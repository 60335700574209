import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import RegisterService from "../../services/RegisterService";
import ModalComponent from "../modalComponent/ModalComponent";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import { GrDocumentText } from "react-icons/gr";

const SignupContract = () => {
 const [showLoader, setShowLoader] = useState(false);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [message, setMessage] = useState();
 const { id } = useParams();
 const modalInitState = useSelector((state) => state.modal);
 const sigCanvas = useRef(null);
 // const [error, setError] = useState(""); // Error state for validation
 const [error, setError] = useState({ clientName: "", signature: "" });
 const [contractData, setContractData] = useState(null); // State to hold contract data
 const [currentDate, setCurrentDate] = useState({
 day: "",
 month: "",
 year: "",
 }); // State to hold contract data
 const [clientName, setClientName] = useState(""); // State for client name
 const [signupContractData, setSignupContractData] = useState({
 userId: "",
 signature: "",
 clientName: "",
 }); 
 const [hideContent, setHideContent] = useState(false);
 const [closeAckPopup, setCloseAckPopup] = useState(false);

console.log(hideContent, "hideContent");
const ackFlag=localStorage.getItem("ackFlag");

 useEffect(() => {
if(closeAckPopup == true){
 if(ackFlag == 1 || ackFlag == 2){
 setHideContent(true);
 } else{
 setHideContent(false);

 }
}

 }, [closeAckPopup, ackFlag]);
 useEffect(() => {
 // Fetch contract data on component mount
 setShowLoader(true);
 const currentDate = new Date();
 const day = currentDate.getDate();
 const month = currentDate.toLocaleString("default", { month: "long" }); // Full month name
 const year = currentDate.getFullYear();

 RegisterService.getSignupContractById(id)
 .then((response) => {
 setShowLoader(false);
 if (response.status === 200) {
 const data = response.data;

 // Add date placeholders to contract data
 const formattedData = {
 day: day,
 month: month,
 year: year,
 };

 setContractData(response.data); // Set contract data to state
 setCurrentDate(formattedData);
 } else {
 setError("Failed to fetch contract data");
 }
 })
 .catch((error) => {
 setShowLoader(false);
 if (error.message === "Network Error") {
 setMessage("Network Error");
 } else {
 switch (error.response ? error.response.status : "") {
 case 401:
 setMessage(error.response.data.failedReason);
 break;
 case 403:
 //show internal error
 dispatch(openModal("internal error"));
 break;
 case 404:
 dispatch(openModal("link expired"));
 break;
 case 500:
 setMessage("Server error occured. Please try again later.");
 dispatch(openModal("server error"));
 break;
 case 406:
 dispatch(openModal("already acknowledge"));
 break;
 case 409:
 dispatch(openModal("already verified"));
 break;
 case 412:
 dispatch(openModal("unknown Error"));
 break;
 default:
 dispatch(openModal("unknown Error"));
 break;
 }
 }
 });
 }, [id]);

 // Clear the signature pad
 const clear = () => {
 sigCanvas.current.clear();
 setError(""); // Reset error on clear
 };

 // Save the signature with validation
 const save = () => {
 const newError = { clientName: "", signature: "" };

 if (!clientName.trim()) {
 newError.clientName = "Name is required.";
 }

 if (sigCanvas.current.isEmpty()) {
 newError.signature = "Signature is required.";
 }

 if (newError.clientName || newError.signature) {
 setError(newError);
 return;
 }
 setShowLoader(true);
 const signature = sigCanvas.current
 .getTrimmedCanvas()
 .toDataURL("image/png");
 setError("");
 const signupContractData = {
 userId: id,
 signature: signature,
 clientName: clientName,
 };
 setSignupContractData(signupContractData);
 RegisterService.signupContractAcknowledge(signupContractData)
 .then((response) => {
 setShowLoader(false);

 if (response.status == 200) {
 dispatch(openModal("acknowledgement success"));
 } else {
 if (response.data && response.data.failedReason) {
 setError(response.data.failedReason);
 } else {
 setMessage("acknowlegdement failed for an unknown reason.");
 }
 // setCode(response.status);
 }
 })
 .catch((error) => {
 setShowLoader(false);
 if (error.message === "Network Error") {
 setMessage("Network Error");
 } else {
 switch (error.response ? error.response.status : "") {
 case 401:
 setMessage(error.response.data.failedReason);
 break;
 case 403:
 //show internal error
 dispatch(openModal("internal error"));
 break;
 case 404:
 dispatch(openModal("internal error"));
 break;
 case 500:
 setMessage("Server error occured. Please try again later.");
 dispatch(openModal("server error"));
 break;
 case 406:
 dispatch(openModal("already acknowledge"));
 break;
 case 409:
 dispatch(openModal("already verified"));
 break;
 case 412:
 dispatch(openModal("unknown Error"));
 break;
 default:
 dispatch(openModal("unknown Error"));
 break;
 }
 }
 });
 };

 return (
 <>
 {
 !hideContent ? 
 <>
 {showLoader && <Loader />}
 {!showLoader && id ? (
 contractData ? (
 <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5 text-justify">
 <div className="flex justify-center items-center gap-2 mb-2 mx-auto">
 <GrDocumentText className="text-xl" />
 <h2 className="text-xl font-semibold">
 Retailer/Dealer Agreement
 </h2>
 </div>
 <div className="border custom-border-50 rounded-lg p-3 flex flex-col lg:items-center mb-3">
 <div className="">
 <div>
 <div className="custom-border-50 rounded-lg bg-[#0f766d]/10 p-3">
 <p>
 <b>THIS AGREEMENT</b> is made on this {currentDate.day}{" "}
 day of {currentDate.month}, <b>{currentDate.year}</b>.
 </p>
 <p>
 <strong>BETWEEN:</strong>
 </p>
 <ol className="pl-5">
 <li className="list-disc">
 GK Telecom Ltd, a company incorporated in <b>UK</b> with
 registration number <b>7002607</b> and whose registered
 office is at{" "}
 <b>
 Alpha House, 296 Kenton Road, Kenton, Harrow,
 Middlesex, HA3 8DD
 </b>{" "}
 (hereinafter referred to as “Company”), and
 </li>

 <li className="list-disc"> <b>{contractData.dealerName}</b>, a company/person incorporated/registered in <b>{contractData.country}</b> with registration number <b>{contractData.companyRegNo}</b> and whose registered office is at <b>{contractData.address}</b> (hereinafter referred to as “Retailer”).</li>
 </ol>
 </div>

 <h2 className="mt-2 font-semibold">BACKGROUND:</h2>
 <p>
 The Company appoints the Retailer as a non-exclusive
 representative to market, sell, and support the Company’s
 products and services under the terms of this Agreement.
 </p>
 <hr className="w-full h-[1px] border my-4" />
 <h2 className="font-semibold mt-2">
 1. DEFINITIONS AND INTERPRETATIONS
 </h2>
 <h3 className="font-medium pl-4 mt-2">1.1 Definitions:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 <strong>“Products”</strong> refers to the eSIMs, USIM
 packs, SIM Cards and related services provided by the
 Company. This ensures clarity about the items the Retailer
 will sell.
 </li>
 <li className="list-disc">
 <strong>“Services”</strong> refers to mobile connectivity
 services, contract sim, customer support, and any other
 services offered by the Company. This defines the scope of
 services Retailers can market and provide support for.
 </li>
 <li className="list-disc">
 <strong>“Agreement”</strong> refers to this
 Retailer/Dealer/Shop Owner Agreement, setting out the
 contractual framework between the parties.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">
 2. APPOINTMENT AND SCOPE
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 2.1 The Company hereby appoints the Retailer as a
 non-exclusive retailer to:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Market and sell the Products and Services, allowing
 flexibility for the Company to appoint other retailers.
 </li>
 <li className="list-disc">
 Provide support to customers as outlined in this
 Agreement, ensuring that customer assistance aligns with
 Company policies.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 2.2 The Retailer agrees to:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Act in accordance with this Agreement to maintain high
 standards of professionalism.
 </li>
 <li className="list-disc">
 Obtain all necessary licenses and approvals for their
 operations, ensuring legal compliance.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">
 3. RETAILER RESPONSIBILITIES
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 3.1 Sales and Marketing:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Use approved sales scripts and promotional materials to
 ensure accurate and consistent communication.
 </li>
 <li className="list-disc">
 Follow ethical and legal practices in selling the
 Company’s Products and Services, avoiding any form of
 misrepresentation.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">3.2 Compliance:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Ensure adherence to regulatory requirements, including
 Ofcom guidelines and anti bribery laws, to maintain
 credibility and legality.
 </li>
 <li className="list-disc">
 Participate in mandatory training provided by the Company
 to stay updated on processes and regulations.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">3.3 Reporting:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Submit periodic sales and compliance reports to the
 Company, enabling transparency and performance monitoring.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 3.4 Data Protection:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 {" "}
 Maintain confidentiality of customer information to build
 trust and comply with legal requirements.
 </li>
 <li className="list-disc">
 {" "}
 Comply with GDPR and data protection laws to avoid
 penalties and reputational damage.
 </li>
 <li className="list-disc">
 {" "}
 Ensure all personal data collected is processed lawfully,
 fairly, and transparently.
 </li>
 <li className="list-disc">
 {" "}
 All personal/customer data must be adequate, relevant, and
 limited to what is necessary for the purposes outlined in
 this Agreement.{" "}
 </li>
 <li className="list-disc">
 {" "}
 Retailers are strictly prohibited from storing,
 distributing, selling, or reselling any personal or
 customer data used to sell products or services.
 </li>
 <li className="list-disc">
 {" "}
 Implement and maintain robust security measures to protect
 personal data against unauthorized access, loss, or
 damage. This includes encryption, access controls, and
 regular security audits.
 </li>
 <li className="list-disc">
 {" "}
 Provide clear and accessible privacy notices to
 individuals, explaining how their data is used, their
 rights under the GDPR, and how they can exercise these
 rights.
 </li>
 <li className="list-disc">
 {" "}
 Conduct regular training for staff handling personal data
 to ensure awareness of GDPR compliance requirements.
 </li>
 <li className="list-disc">
 Notify the Company immediately in the event of a data
 breach that may pose a risk to individuals’ rights and
 freedoms, ensuring compliance with GDPR’s 24-hour
 reporting requirement. Failure to do so may result in a
 fine of £1000 for any delay in reporting.
 </li>
 <li className="list-disc">
 {" "}
 Ensure that no third-party processors engaged to handle
 personal data comply with equivalent GDPR standards.
 </li>
 <li className="list-disc">
 {" "}
 Facilitate the rights of individuals, including the right
 to access, rectify, erase, or restrict processing of their
 data, and respond promptly to any such requests and bring
 this to the attention of the company.
 </li>
 <li className="list-disc">
 {" "}
 Maintain records of processing activities as required by
 Article 30 of the GDPR.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">
 4. PRODUCTS AND SERVICES
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 4.1 Supply of Products:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 The Retailer shall order Products through the Company’s
 prescribed processes to ensure inventory management and
 quality control.
 </li>
 <li className="list-disc">
 The Company reserves the right to accept or reject any
 orders to align supply with strategic objectives.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">4.2 Ownership:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 The Retailer acknowledges that ownership of eSIMs and USIM
 packs / SIM Card remains with the Company until sold to
 customers, safeguarding Company assets.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">5. FINANCIAL TERMS</h2>
 <h3 className="font-medium pl-4 mt-2">
 5.1 Pricing and Payment:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 The Retailer shall sell Products in accordance with the
 pricing structure provided by the Company through its
 website, marketing materials, or training sessions to
 maintain consistency and transparency.
 </li>
 <li className="list-disc">
 Retailers are strictly prohibited from charging customers
 any additional fees or surcharges beyond the specified
 product prices.
 </li>
 <li className="list-disc">
 All financial transactions must comply with this policy to
 ensure fairness and trust in the sales process.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 5.2 Incentives and Commissions:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 The Retailer is entitled to commissions for each sale,
 payable after a 30-day cooling-off period.
 </li>
 <li className="list-disc">
 If a customer cancels their contract within the 30-day
 cooling-off period, the corresponding commission will be
 rendered void.
 </li>
 <li className="list-disc">
 In cases of fraud, gambling, or other suspicious
 activities, the Company reserves the right to withhold or
 void commissions. Additionally, commissions already paid
 under such circumstances may be subject to clawback.
 </li>
 <li className="list-disc">
 The Company reserves the right to withhold payments in
 cases of non-compliance with this Agreement, ensuring
 accountability and adherence to agreed-upon standards.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">
 6. COMPLIANCE AND AUDITS
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 6.1 The Retailer agrees to:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Submit to periodic audits by the Company to ensure
 compliance with this Agreement, maintaining integrity.
 </li>
 <li className="list-disc">
 Provide access to records and systems as required to
 verify adherence to agreed standards.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 6.2 The Company reserves the right to terminate this
 Agreement for data breach or material breaches or
 non-compliance, emphasizing the importance of following the
 rules.
 </h3>
 <h2 className="font-semibold mt-2">7. CONFIDENTIALITY</h2>
 <h3 className="font-medium pl-4 mt-2">
 7.1 The Retailer shall:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Maintain the confidentiality of all proprietary and
 customer data to protect business interests and customer
 trust.
 </li>
 <li className="list-disc">
 Use such data only for purposes authorized under this
 Agreement, avoiding misuse.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 7.2 The obligations under this clause shall survive the
 termination of this Agreement, ensuring long-term data
 protection.
 </h3>
 <h2 className="font-semibold mt-2">
 8. TERM AND TERMINATION
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 8.1 This Agreement shall commence on the Effective Date and
 continue until terminated:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 By either party with 30 days’ written notice, allowing
 flexibility to end the relationship.
 </li>
 <li className="list-disc">
 By the Company for material breach, insolvency, or
 regulatory violations by the Retailer, ensuring serious
 issues are addressed promptly.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">
 8.2 Upon termination, the Retailer shall:
 </h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Return all unsold Products and Company materials to
 protect Company assets.
 </li>
 <li className="list-disc">
 Cease using the Company’s trademarks and branding to avoid
 brand misuse.
 </li>
 </ul>
 <h2 className="font-semibold mt-2">
 9. GOVERNING LAW AND DISPUTES
 </h2>
 <h3 className="font-medium pl-4 mt-2">
 9.1 This Agreement shall be governed by the laws of the
 United Kingdom, ensuring clarity on the legal framework.
 </h3>
 <h3 className="font-medium pl-4 mt-2">
 9.2 Disputes arising under this Agreement shall be resolved
 by arbitration in the United Kingdom, offering a structured
 resolution process.
 </h3>
 <h2 className="font-semibold mt-2">10. MISCELLANEOUS</h2>
 <h3 className="font-medium pl-4 mt-2">10.1 Amendments:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 This Agreement may only be amended in writing signed by
 both parties, ensuring mutual agreement.
 </li>
 </ul>
 <h3 className="font-medium pl-4 mt-2">10.2 Force Majeure:</h3>
 <ul className="pl-10 mb-4">
 <li className="list-disc">
 Neither party shall be liable for delays or failures
 caused by events beyond their reasonable control,
 protecting against unforeseen circumstances.
 </li>
 </ul>
 <p style={{ marginTop: "20px" }}>
 IN WITNESS WHEREOF, the parties have executed this Agreement
 as of the date first above written.
 </p>
 <p>&nbsp;</p>
 <div className="w-full grid grid-cols-1 md:grid-cols-2">
 <div className="md:block hidden"></div>

 <div className="w-full ml-auto flex flex-col justify-end gap-3 mb-5">
 <p>Signed By Name:</p>
 <div className="flex flex-col">
 <input
 type="text"
 id="clientName"
 value={clientName}
 onChange={(e) => setClientName(e.target.value)}
 className="block rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
 />
 {error.clientName && (
 <p style={{ color: "red" }}>{error.clientName}</p>
 )}
 </div>
 </div>
 </div>

 {/* Signature Pad */}
 <div className="flex flex-col justify-end items-end">
 <div className="w-full grid grid-cols-1 md:grid-cols-2">
 <div className="mb-2 md:mb-0 flex flex-col">
 <p className="mt-auto">
 <b>GK Telecom Ltd</b>
 </p>
 <p>Digitally signed by GK Telecom Ltd</p>
 </div>
 <div className="flex flex-col">
 <SignatureCanvas
 ref={sigCanvas}
 penColor="black"
 canvasProps={{
 width: 500,
 height: 200,
 className:
 "sigCanvas block rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-30 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6",
 }}
 />
 {error.signature && (
 <p style={{ color: "red" }}>{error.signature}</p>
 )}
 </div>
 </div>
 {/* <p>Digitally signed by GK Telecom Ltd</p> */}
 </div>
 {/* Buttons for clearing and saving */}
 <div className="w-full flex justify-end items-center gap-3 mt-1">
 <button
 className="cta-two text-lg py-2 px-8 rounded-lg my-2"
 onClick={clear}
 >
 Clear
 </button>
 <button
 className="cta-one text-lg py-2 px-8 rounded-lg my-2"
 onClick={save}
 >
 Save
 </button>
 </div>
 {/* Error message if validation fails */}
 </div>
 </div>
 </div>
 </main>
 ) : (
 <p>No contract data found!</p>
 )
 ) : (
 <div>
 <h1>Error</h1>
 <p>No ID provided in the URL!</p>
 </div>
 )}

 <ModalComponent
 size="md"
 type={
 modalInitState.contentId === "acknowledgement success" ||
 "already acknowledge" 
 ? "success" :
 modalInitState.contentId === "link expired" ? "warning"
 : "failed"
 }
 crossButton={
 modalInitState.contentId === "acknowlegdement success" ||
 "already acknowledge"
 ? false
 : true
 }
 position="center"
 title={
 modalInitState.contentId === "network error" ||
 modalInitState.contentId === "internal error" ||
 modalInitState.contentId === "server error" ||
 modalInitState.contentId === "unknown error" ||
 modalInitState.contentId === "already acknowledge"
 ? "Acknowledgement is Already Done."
 : modalInitState.contentId === "acknowledgement success"
 ? "Acknowledgement Successful"
 : modalInitState.contentId === "link expired"
 ? "Link Expired"
 : "Error Occurred"
 }
 description=""
 bottomLine={true}
 >
 <div>
 {modalInitState.contentId === "network error" ? (
 <p>Network Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "internal error" ? (
 <p>Internal Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "server error" ? (
 <p>Server Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "unknown error" ? (
 <p>Unknown Error Occurred. Please try again later.</p>
 ) : modalInitState.contentId === "link expired" ? (
 <p>This link has expired. Please request a new link and try again.</p>
 ) : modalInitState.contentId === "already acknowledge" ? (
 <div className="flex flex-col">
 <p>Acknowledement is Already Done.</p>
 <Button
 handleClick={() => {
 // navigate("/");
 dispatch(closeModal());
 localStorage.setItem("ackFlag", 2);
 setCloseAckPopup(true);
 }}
 title="Close"
 type="button"
 style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
 />
 </div>
 ) : modalInitState.contentId === "acknowledgement success" ? (
 <div className="flex flex-col">
 <p>Acknowledgement Done Successfully.</p>
 <Button
 handleClick={() => {
 // navigate("/");
 dispatch(closeModal());
 setCloseAckPopup(true);
 localStorage.setItem("ackFlag", 1);
 }}
 title="Close"
 type="button"
 style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
 />
 </div>
 ) : null}
 </div>
 </ModalComponent>

 {showLoader && <Loader />}
 </> :
 <p></p>
 }
 </>
 );
};

export default SignupContract;