import React from "react";
export const faqContent = [
  {
    id: 1,
    question: "How do I order a free SIM?",
    answer: (
      <>
        <p className="text-sm">
          Go to{" "}
          <a
            className="underline"
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.three.co.uk/shop/sim-only/payg"
          >
            www.three.co.uk/shop/sim-only/payg
          </a>
           and select the Pay As You Go plan when adding to your basket.
        </p>
        <br />
        <p className="text-sm">
          No need to choose a Data Pack – skip this by hitting Continue to get
          your free SIM.
        </p>
      </>
    ),
  },
  {
    id: 2,
    question: "What is a Pay As You Go SIM?",
    answer: (
      <>
        <p className="text-sm">
          A Pay As You Go SIM card fits into your existing phone, and gives you
          access to our network. You aren’t tied into a contract, so you can add
          a{" "}
          <a
            className="underline"
            href="https://www.three.co.uk/pay-as-you-go/payg-data-packs"
            target="__blank"
            rel="noopener noreferrer"
          >
            Data Pack
          </a>{" "}
          or top up your credit to use your phone as much or as little as you
          need to.
        </p>
      </>
    ),
  },
  {
    id: 3,
    question: "How does a Pay As You Go SIM work?",
    answer: (
      <>
        <p className="text-sm">
          Your Pay As You Go SIM card gives you the flexibility of not being on
          a contract. Once you’ve inserted your SIM and downloaded our Three
          app, you can add a{" "}
          <a
            className="underline"
            href="https://www.three.co.uk/pay-as-you-go/payg-data-packs"
            target="__blank"
            rel="noopener noreferrer"
          >
            Data Pack
          </a>{" "}
          which gives you a month’s worth of data, calls and texts. You have the
          option to renew each month without being tied into a 12, 24 or 36
          month contract.
        </p>
      </>
    ),
  },
  {
    id: 4,
    question: "How to activate my Pay As You Go SIM?",
    answer: (
      <>
        <p className="text-sm">
          Once you’ve received your free SIM in the post, it’s easy to get set
          up in 3 simple steps.
        </p>
        <br />
        <ul className="text-sm list-disc pl-4">
          <li>Insert your SIM - you'll get a text with your new number.</li>
          <li>Download the Three app and register your details.</li>
          <li>
            Select your Data Pack or top up with credit, and you’re ready to go.
            If you set up auto-renew, which means your Data Pack will keep
            renewing, you’ll get extra data on us, every month.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    question: "Do Pay As You Go SIM cards expire?",
    answer: (
      <>
        <p className="text-sm">
          Pay As You Go SIMs can expire. To keep your number active, you must
          use it for at least one chargeable activity every 180 days. A
          ‘chargeable activity’ is something like making a call, sending a text,
          using your data, or using an existing top-up credit or Data Pack
          allowance.
        </p>
      </>
    ),
  },
  {
    id: 6,
    question: "Do Pay As You Go SIMs work abroad?",
    answer: (
      <>
        <p className="text-sm">
          Yes, you can{" "}
          <a
            className="underline"
            href="https://www.three.co.uk/support/roaming-and-calling-abroad/roaming-abroad/go-roam"
            target="__blank"
            rel="noopener noreferrer"
          >
            Go Roam
          </a>{" "}
          in over 70 worldwide destinations with your Pay As You Go SIM. As long
          as you’ve activated and used your SIM in the UK before you go – like
          by sending a text, making a call, or using data – it will work when
          you’re abroad.
          <br />
          Check{" "}
          <a
            className="underline"
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.three.co.uk/support/roaming-and-calling-abroad/roaming-abroad/roaming-destinations?#countries1"
          >
            {" "}
            where in the world you can Go Roam at no extra cost.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 7,
    question: "How do I top up my Pay As You Go SIM?",
    answer: (
      <>
        <p className="text-sm">
          There are a few ways to top up. The easiest way is through the Three
          app, where you can also track your usage and manage your spend. But
          you can also:
        </p>
        <br />
        <ul className="text-sm list-disc pl-4">
          <li>Call 444 free using your Three SIM</li>
          <li>Visit three.co.uk/top-up</li>
          <li>Register your My3 account at three.co.uk/my3</li>
        </ul>
        <br />
        <p className="text-sm">
          You get the best value with our Data Packs, which give you a month’s
          worth of calls, texts, and data. Or you can top up your credit, which
          gets used up gradually as you call, text, and go online.
        </p>
      </>
    ),
  },
  {
    id: 8,
    question: "How do I select a Data Pack?",
    answer: (
      <>
        <p className="text-sm">
          Once you’ve received your free SIM, then you’ll need to download the
          Three app where you can register your SIM, and browse all the Data
          Packs we have on offer.
        </p>
      </>
    ),
  },
];
