import React, { useEffect, useRef, useState } from "react";
import { Bars3Icon, KeyIcon } from "@heroicons/react/24/outline";
import logo from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import toast, { Toaster } from "react-hot-toast";
import { LuSettings } from "react-icons/lu";
import { IoKeyOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import Button from "../button/Button";
import { useSelector, useDispatch } from "react-redux";
import { handleLogIn, selectLoggedIn } from "./navbarSlice";
import { removeUser } from "../login/userSlice";
import { WEB_URL } from "../../constant/Constant";

export default function Navbar() {
  const isLoggedIn = localStorage.getItem("loggedIn");
  const dispatch = useDispatch();
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showNavmenu, setShowNavmenu] = useState(false);
  const [isUser, setIsUser] = useState(false);
  // const [referralCode, setReferralCode] = useState("GKT-575VDF796FDUN");
  const navbarMenuRef = useRef(null);
  const navbarButtonRef = useRef(null);
  const navigate = useNavigate();
  const referralCode = localStorage.getItem("reffralCode") || "";
  const userRoleId = localStorage.getItem("roleId") || "";
  const location = useLocation();
  const route = location.pathname.split("/")[1];

  const MenuListItems = [
    {
      id: 1,
      title: "Home",
      links: "/",
    },
    // Conditionally include the Dashboard item based on roleId
    ...(userRoleId === "ROLE_DEALER"
      ? [
          {
            id: 2,
            title: "Dashboard",
            links: "/dashboard",
          },
        ]
      : []),
    // {
    //   id: 3,
    //   title: "Partner",
    //   links: "/partner",
    // },
    {
      id: 4,
      title: "About Us",
      links: "/about-us",
    },
    // {
    //   id: 5,
    //   title: "Blogs",
    //   links: "/blogs",
    // },
    {
      id: 6,
      title: "FAQ's",
      links: "/faq",
    },
    {
      id: 7,
      title: "IoT Devices",
      links: "/iot-devices",
    },
  ];

  // State to manage userRoleId
  const [RoleId, setRoleId] = useState(localStorage.getItem("roleId") || "");

  useEffect(() => {
    const handleStorageChange = () => {
      setRoleId(localStorage.getItem("roleId") || "");
    };

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleFunc = () => {
    dispatch(handleLogIn());
    // setIsLoggedIn(!isLoggedIn);
  };
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      dispatch(handleLogIn());
      // setIsLoggedIn(true);
    }
  }, []);
  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("email");
    localStorage.removeItem("emailId");
    localStorage.removeItem("name");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    localStorage.removeItem("userId");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("roleId");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("reffralCode");

    localStorage.removeItem("dealerId");
    localStorage.removeItem("dealerFirstName");
    localStorage.removeItem("dealerLastName");
    localStorage.removeItem("dealerEmailId");
    localStorage.removeItem("emailId");
    localStorage.removeItem("dealerSignup");
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("username");
    localStorage.removeItem("dealerUsername");
    localStorage.removeItem("dealerPassword");
    localStorage.removeItem("dealerAccesstoken");
    localStorage.removeItem("verifiedCount");
    localStorage.removeItem("name");
    localStorage.removeItem("referredByUserId");

    dispatch(removeUser());
    navigate("/");
  };

  const isReferralCodeValid =
    referralCode !== null &&
    referralCode !== "undefined" &&
    referralCode !== "";

  const MenuTray = ({ customClass }) => (
    <ul ref={navbarMenuRef} className={customClass}>
      <li className="truncate px-0 lg:px-5 py-2 mb-2 pointer-events-none cursor-none font-medium">
        {localStorage.getItem("emailId")}
      </li>
      <li className="truncate px-0 lg:px-5 py-2 mb-2 pointer-events-none cursor-none font-medium">
        {localStorage.getItem("name")}
      </li>
      <hr className="py-1 hidden lg:block" />
      {isReferralCodeValid && (
        <li
          onClick={handleReferralCodeCopy}
          className="flex flex-col px-0 lg:px-5 py-2 mb-2 cursor-pointer font-medium"
        >
          <div className="w-full flex justify-between items-center">
            <div className="overflow-x-hidden">
              <span className="text-sm font-medium">Referral Code:</span>
              <p className="truncate font-semibold">{referralCode}</p>
            </div>
            <div>
              <FaRegCopy className="text-xl ml-3" />
            </div>
          </div>
          <span className="text-xs text-slate-400 font-normal"></span>
        </li>
      )}
      {isReferralCodeValid && (
        <li
          onClick={handleReferralUrlCopy}
          className="flex flex-col px-0 lg:px-5 py-2 mb-2 cursor-pointer font-medium"
        >
          <div className="w-full flex justify-between items-center">
            <div className="overflow-x-hidden">
              <span className="text-sm font-medium">Referral URL:</span>
              <p className="truncate font-semibold text-sm">
                {WEB_URL}{referralCode}
              </p>
            </div>
            <div>
              <FaRegCopy className="text-xl ml-3" />
            </div>
          </div>
          <span className="text-xs text-slate-400 font-normal"></span>
        </li>
      )}
      {/* <li className="flex justify-between items-center px-0 lg:px-5 py-2 mb-2 cursor-pointer font-medium">
        <p className="truncate">Setting</p>
        <LuSettings className="text-2xl ml-3" />
      </li> */}
      <li
        onClick={() => navigate("/changePassword")}
        className="flex justify-between items-center px-0 lg:px-5 py-2 mb-2 cursor-pointer font-medium"
      >
        <p className="truncate">Change Password</p>
        <div>
          <IoKeyOutline className="text-2xl ml-3" />
        </div>
      </li>
      <li
        onClick={() => navigate("/myorder")}
        className="flex justify-between items-center px-0 lg:px-5 py-2 mb-2 cursor-pointer font-medium"
      >
        <p className="truncate">
          {userRoleId === "ROLE_DEALER" ? "Customer Order Data" : "My Plans"}
        </p>
        <div>
          <FiShoppingBag className="text-2xl ml-3" />
        </div>
      </li>
      <hr className="py-1 hidden lg:block mb-2" />
      <li
        className="w-full flex justify-between items-center px-0 lg:px-5 py-2 cursor-pointer font-medium"
        onClick={handleSignOut}
      >
        <p>Sign out</p>
        <div>
          <TbLogout className="text-2xl" />
        </div>
      </li>
    </ul>
  );
  // copy clipboard
  const handleReferralCodeCopy = () => {
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        toast.success("Referral URL copied successfully!", {
          style: {
            border: "1px solid #65D14E",
          },
        });
      })
      .catch((err) => {
        toast.error("Error copying referral code", {
          style: {
            border: "1px solid red",
          },
        });
      });
  };
  const handleReferralUrlCopy = () => {
    navigator.clipboard
      .writeText(WEB_URL + referralCode)
      .then(() => {
        toast.success("Referral code copied successfully!", {
          style: {
            border: "1px solid #65D14E",
          },
        });
      })
      .catch((err) => {
        toast.error("Error copying referral code", {
          style: {
            border: "1px solid red",
          },
        });
      });
  };

  const handleCloseNavMenu = (event) => {
    if (
      showNavmenu &&
      navbarMenuRef.current &&
      navbarButtonRef.current &&
      !navbarMenuRef.current.contains(event.target) &&
      !navbarButtonRef.current.contains(event.target)
    ) {
      setShowNavmenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseNavMenu);

    return () => {
      document.removeEventListener("click", handleCloseNavMenu);
    };
  }, [showNavmenu]);

  return (
    <header className="sticky top-0 z-20 shadow mb-6 ">
      <nav style={{ zIndex: 2 }} className="px-4 lg:px-6 relative bg-secondary">
        {/* flex flex-wrap justify-between items-center mx-auto */}
        <div className="w-full grid grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
          <Link onClick={handleFunc} to="/" className="flex items-center">
            <img src={logo} className="mr-3 h-20 p-3" alt="GKT" />
          </Link>
          <ul className="hidden lg:flex justify-center items-center gap-14">
            {MenuListItems.map((item, index) => (
              <li
                className={`block nav-link mb-5 lg:mb-0 font-medium`}
                key={item.id}
              >
                <Link
                  to={item.links}
                  className={`${
                    "/" + route == item.links
                      ? "border-b-2 border-[#6ea8ad]"
                      : ""
                  } anchor whitespace-nowrap`}
                >
                  {item.title}
                </Link>
              </li>
            ))}
            {isLoggedIn && <MenuTray customClass="block lg:hidden" />}
          </ul>
          <div className="flex justify-end items-center cursor-pointer gap-1">
            {isLoggedIn ? (
              <div ref={navbarButtonRef} className="relative">
                {isUser ? (
                  <img
                    onClick={() =>
                      setShowNavmenu((showNavmenu) => !showNavmenu)
                    }
                    className={`${
                      showNavmenu ? "border-2 border-teal-600" : "border-none"
                    } h-10 w-10 rounded-md`}
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                ) : (
                  <UserCircleIcon
                    onClick={() =>
                      setShowNavmenu((showNavmenu) => !showNavmenu)
                    }
                    className={`w-12 rounded-[100px] ${
                      showNavmenu ? "border-2 border-teal-600" : "border-none"
                    }`}
                  />
                )}
                {isLoggedIn && (
                  <Transition
                    show={showNavmenu}
                    enter="transition-all duration-300 transform translate-x-6"
                    enterFrom="opacity-0 transform translate-x-6"
                    enterTo="opacity-100 transform translate-x-0"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 transform translate-x-0"
                    leaveTo="opacity-0 transform translate-x-6"
                  >
                    <div className="card menu-tray shadow-md hidden lg:block absolute right-0 top-14 p-2 w-64">
                      <MenuTray />
                    </div>
                  </Transition>
                )}
              </div>
            ) : (
              <>
                <Link to="/login" className="">
                  <Button title="Log in" type="button" style="cta-two"></Button>
                </Link>
                <Link to="/register" className="">
                  <Button title="Get started" type="button" style="cta-one" />
                </Link>
              </>
            )}
            {isLoggedIn ? null : (
              <div
                style={{ width: "40px", height: "40px" }}
                width={40}
                height={40}
                className="lg:hidden cursor-pointer transition-all ease-in-out md:mr-2 rounded flex justify-center items-center border-teal-600 border hover:border-2"
                onClick={() => setShowNavmenu((showNavmenu) => !showNavmenu)}
              >
                {showNavmenu ? (
                  <XMarkIcon
                    width={32}
                    height={32}
                    type="button"
                    className="flex items-center p-0 text-sm text-teal-600"
                  />
                ) : (
                  <Bars3Icon
                    width={32}
                    height={32}
                    type="button"
                    className="flex items-center p-0 text-sm text-teal-600"
                    aria-controls="mobile-menu-2"
                    aria-expanded="false"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
      <Transition
        show={showNavmenu}
        enter="transition-all duration-300 transform -translate-y-6"
        enterFrom="opacity-0 transform -translate-y-6"
        enterTo="opacity-100 transform translate-y-0"
        leave="transition-all duration-150"
        leaveFrom="opacity-100 transform translate-y-0"
        leaveTo="opacity-0 transform -translate-y-6"
      >
        <div
          style={{ zIndex: 3 }}
          className="flex-col px-4 lg:px-6 py-2.5 border-b border-teal-600 bg-secondary absolute lg:relative justify-center items-start w-full lg:hidden flex lg:w-auto lg:order-1"
        >
          <ul className="flex flex-col justify-center items-start">
            {MenuListItems.map((item, index) => (
              <li className="block nav-link mb-5 font-medium" key={item.id}>
                <Link to={item.links} className="">
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
          {isLoggedIn && <MenuTray customClass="w-full block lg:hidden" />}
        </div>
      </Transition>
      <Toaster position="top-center" reverseOrder={false} />
    </header>
  );
}
