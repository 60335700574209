import React, { useEffect, useState } from "react";
import "../../App.css";
// import Product from "./Product";
import heroBg from "../../assets/hero-bg.jpg";
import {
  ArrowRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import Navbar from "../navbar/Navbar";
import Product from "../product/Products";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";
import Button from "../button/Button";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { API_URL } from "../../constant/Constant";";
import { useNavigate } from "react-router-dom";
import CustomCarousel from "../caraousel/CustomCarousel";
import CookiesComponent from "../cookies/CookiesComponent";
import { openCookies } from "../cookies/cookieSlice";
import Cookies from "js-cookie";
import { Link, useLocation, useParams } from "react-router-dom";
//close the model
export default function Home() {
  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInitState = useSelector((state) => state.modal);

  const customerVerified = localStorage.getItem("customerVerified");
  localStorage.removeItem("orderConfirmation");
  const {referralCode} = useParams();

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem("sessionReferralCode", referralCode);
      navigate("/", { replace: true });
    }
  }, [referralCode, navigate]);


  useEffect(() => {
    //console.log("customerVerified", customerVerified);
    if (customerVerified == 1) {
      dispatch(openModal("customerVerified"));
      localStorage.removeItem("customerVerified");
    }

    //check if cookie popup tobe opened
    const cookiesPreferences = Cookies.get("cookiesPreferences") && !(Object.keys(JSON.parse(Cookies.get("cookiesPreferences"))).length === 0 && JSON.parse(Cookies.get("cookiesPreferences")).constructor === Object) ? JSON.parse(Cookies.get("cookiesPreferences")) : undefined;
    if (!cookiesPreferences) {
      dispatch(openCookies());
    }

  }, []);

  useEffect(() => {
    const emailSendSuccess = localStorage.getItem("emailSendSuccess");

    if (emailSendSuccess === "1") {
      dispatch(openModal("emailSendPopup"));
      localStorage.setItem("emailSendSuccess", "0");
    }
  }, []);
  
  //for dealer welcome popup
  useEffect(() => {
    const welcomePopup = localStorage.getItem("welcomePopup");

    if (welcomePopup === "1") {
      dispatch(openModal("welcomePopup"));
      localStorage.setItem("welcomePopup", "0");
    }
  }, []);

  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        {/* <div className="border custom-border-50 rounded-lg"> */}
        <CustomCarousel />
        {/* </div> */}
        {/* <div className="flex flex-col lg:flex-row items-center border custom-border-50 rounded-lg h-auto lg:h-[300px]">
          <div className="w-full lg:w-1/2">
            <img
              className="rounded-lg h-auto w-full lg:h-[298px] object-cover"
              src={heroBg}
              alt=""
            />
          </div>
          <div className="w-full lg:w-1/2 h-full flex flex-col px-8 py-6">
            <div>
              <h1 className="font-bold text-2xl">
                Find the best SIM only deals for you
              </h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestiae impedit blanditiis officiis.
              </p>
              <div className="relative mt-4 rounded-md shadow-sm">
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border custom-border-50 focus:border-2 placeholder-teal-800 placeholder-opacity-50 focus:custom-border-50 py-1.5 pl-4 pr-20 bg-transparent sm:text-sm sm:leading-6"
                  placeholder="Search"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <button className="h-full cta-one px-3 rounded-md">
                    Search
                  </button>
                </div>
              </div>
            </div>
            <fieldset className="mt-8 lg:mt-auto">
              <div className="flex justify-between items-center">
                <InformationCircleIcon className="w-5" />
                <label htmlFor="price" className="flex font-medium leading-6">
                  Learn more
                  <ArrowRightIcon className="w-5 ml-3" />
                </label>
              </div>
            </fieldset>
          </div>
        </div> */}
        <div className="rounded mt-10 py-2 advt-infinite-strip gap-x-7 border border-[#5A949A]/60 text-[#5A949A]">
          <p>
            ✦ Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
          <p>
            ✦ Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
          <p>
            ✦ Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
          <p>
            ✦ Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
          <p>
            ✦ Buy Any Plan Now and Get Up to{" "}
            <span className="font-semibold">40% Discount Instantly!</span>
          </p>
        </div>
      </main>

      <Product />
      {modalInitState.contentId === "customerVerified" ? (
        <ModalComponent
          size="md"
          type="success"
          position="center"
          description=""
          bottomLine={true}
          title="Verification Successful"
        >
          <div className="flex flex-col">
            <p>Customer Verification Successful.</p>
            <Button
              handleClick={() => {
                dispatch(closeModal());
              }}
              title="Okay"
              type="button"
              style="rounded mx-auto w-1/2 text-sm px-4 lg:px-5 py-2 lg:py-2.5 cta-one rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mt-4 mx-auto font-semibold px-3 py-1.5 text-center"
            />
          </div>
        </ModalComponent>
      ) : null}
      {modalInitState.contentId === "emailSendPopup" ? (
        <ModalComponent
          size={modalInitState.contentId === "emailSendPopup" ? "md" : null}
          type={
            modalInitState.contentId === "emailSendPopup" ? "success" : null
          }
          position={
            modalInitState.contentId === "emailSendPopup" ? "center" : null
          }
          title={
            modalInitState.contentId === "emailSendPopup" ? "Email Send" : null
          }
          description=""
          bottomLine={true}
        >
          {modalInitState.contentId === "emailSendPopup" ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
              <h3 className="mb-3 text-center">
                Verification email has been sent to your registered email ID.
              </h3>
              <Button
                handleClick={() => {
                  dispatch(closeModal("emailSendPopup"));
                }}
                title="Okay"
                type="button"
                style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
              />
            </div>
          ) : null}
        </ModalComponent>
      ) : null}
      {modalInitState.contentId === "welcomePopup" ? (
        <ModalComponent
          size="md"
          type="success"
          position="center"
          title="Welcome to the Dealer Portal!"
          description=""
          bottomLine={true}
        >
          <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
            <h3 className="mb-3 text-center">
              Thank you for joining us. As a new dealer, you now have access to
              the Dashboard and can start selling contract SIMs to your
              customers. To get started, explore the onboarding guide and
              familiarise yourself with the application's features.
              <br />
              <br />
              If you have any questions or need assistance, we’re here to help!
              <br />
              <br />
              <b>Enjoy your journey with us!</b>
            </h3>
            <Button
              handleClick={() => {
                dispatch(closeModal("welcomePopup"));
              }}
              title="Okay"
              type="button"
              style="cta-two w-full rounded text-sm px-4 lg:px-5 py-2 lg:py-2.5 mx-auto mt-2 bg-transparent hover:text-white focus:ring-4 focus:outline-none font-semibold px-3 py-1.5 text-center"
            />
          </div>
        </ModalComponent>
      ) : null}
      
      <CookiesComponent />
      <Footer />
    </>
  );
}
