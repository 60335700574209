import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import useScrollToTop from "../customHooks/useScrollToTop";

const PrivacyPolicy = () => {
  useScrollToTop()
  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <h1 className="text-center text-2xl font-semibold leading-9 tracking-tight primary-text capitalize mb-4">
          Privacy Policy
        </h1>
        <section className="mx-auto max-w-5xl">
          <>
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex flex-col gap-y-2">
                <h2
                  style={{
                    fontSize: "11pt",

                    backgroundColor: "transparent",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GK Telecom Ltd.
                </h2>
                <h2
                  style={{
                    fontSize: "11pt",

                    backgroundColor: "transparent",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Privacy &amp; Data Retention Policy
                </h2>
                <h2
                  style={{
                    fontSize: "11pt",

                    backgroundColor: "transparent",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Data Retention Policy
                </h2>
              </div>
              <div className="mt-5 md:mt-0">
                <p className="font-medium">Effective Date: 01-Sept-19</p>
              </div>
            </div>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                1.Purpose
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The purpose of this Policy is to ensure that necessary records
                and documents of GK Telecom Limited are adequately protected and
                maintained and to ensure that records that are no longer needed
                by GK Telecom Limited or are of no value are discarded at the
                proper time. This Policy is also for the purpose of aiding
                employees of GK Telecom Limited in understanding their
                obligations in retaining electronic documents – including
                e-mail, Web files, text files, sound and video files, PDF
                documents, and all Microsoft Office or other formatted files.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                2.Policy
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                This Policy represents GK Telecom Limited’s policy regarding the
                retention and disposal of records and the retention and disposal
                of electronic documents. &nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3.Administration
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Attached as Appendix A is a Record Retention Schedule that is
                approved as the initial maintenance, retention and disposal
                schedule for physical records of GK Telecom Limited and the
                retention and disposal of electronic documents. We will make
                modifications to the Record Retention Schedule from time to time
                to ensure that it follows National legislation and includes the
                appropriate document and record categories for GK Telecom
                Limited; monitor legislation affecting record retention;
                annually review the record retention and disposal program; and
                monitor compliance with this Policy.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In addition, any retained information can only be used for the
                purpose for which it is stored. This is compliant with the Data
                Protection Act 1998 and the General Data
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "italic",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Protection Regulation (GDPR) (Regulation (EU) 2016/679)&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                4.Suspension of Record Disposal in Event of Legal Proceedings or
                Claims
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                There are certain occasions when information needs to be
                preserved beyond any limits set out in the Policy. The Policy
                must be SUSPENDED relating to a specific customer or document
                and the information retained beyond the period specified in GK
                Telecom Limited’s Data Retention Schedule in the following
                circumstances:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Legal proceedings or a regulatory or similar investigation or
                obligation to produce information are known to be likely,
                threatened or actual.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                A crime is suspected or detected.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Information is relevant to a company in liquidation or
                receivership, where a debt is due to GK Telecom Limited.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Information is considered by the owning unit to be of potential
                historical importance and this has been confirmed by the
                Administrator.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In the case of possible or actual legal proceedings,
                investigations or crimes occurring, the type of information that
                needs to be retained relates to any that will help or harm GK
                Telecom Limited or the other side’s case or liability or amount
                involved.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If there is any doubt over whether legal proceedings, an
                investigation or a crime could occur, or what information is
                relevant or material in these circumstances, the Administrator
                should be contacted, and legal advice sought.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The Administrator shall take such steps as is necessary to
                promptly inform all staff of any suspension in the further
                disposal of documents.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                5.Security of personal information
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                GK Telecom Limited will take reasonable technical and
                organisational precautions to prevent the loss, misuse or
                alteration of your personal information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                GK Telecom Limited will store all personal information on our
                secure (password- and firewall-protected) servers.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The Client should acknowledge that the transmission of
                information over the internet is inherently insecure, and that
                GK Telecom Limited cannot guarantee the security of data sent
                over the internet.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The Client will be responsible for keeping their Username and
                Password used for accessing GK Telecom Limited’s website
                confidential; GK Telecom Limited will not ask for password other
                than when needed to log in to our website.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                6.Amendments
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                GK Telecom Limited may update this policy from time to time by
                publishing a new version.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                This page should be checked occasionally to ensure that the
                policy remains relevant.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                7.Applicability
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                This Policy applies to all physical records generated during GK
                Telecom Limited’s operation, including both original documents
                and reproductions. It also applies to the electronic documents
                described above.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                APPENDIX A RECORD RETENTION SCHEDULE
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The Record Retention Schedule is organised as follows:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                SECTION TOPIC
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Accounting and Finance
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Contracts
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Corporate Records
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Correspondence and Internal Memoranda
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Personal Information
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Electronic Records
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Insurance Records
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Legal
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Miscellaneous
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Personnel Records
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Tax Records
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                1. ACCOUNTING AND FINANCE
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Annual Audit Reports and Financial Statements: Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Annual Audit Records, including work papers and other
                    documents that relate to the audit: 7 years after completion
                    of audit
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Annual Plans and Budgets: &nbsp;7 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Bank Statements and Cancelled Cheques: 7 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Employee Expense Reports: 7 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Interim Financial Statements: 7 years
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                2. CONTRACTS
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Contracts and Related Correspondence (including any proposal
                that resulted in the contract and all other supportive
                documentation): &nbsp;7 years after expiration or termination
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                3. CORPORATE RECORDS
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Corporate Records (minutes, signed minutes of the Board and
                    all committees, record of incorporation, articles of
                    incorporation, annual corporate reports): &nbsp;
                    &nbsp;Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Licenses and Permits: Permanent
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                4. CORRESPONDENCE AND INTERNAL MEMORANDA
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                General Principle: Most correspondence and internal memoranda
                should be retained for the same period as the document to which
                they pertain or support. For instance, a letter pertaining to a
                particular contract would be retained as long as the contract (7
                years after expiration). It is recommended that records that
                support a particular project be kept with the project and take
                on the retention time of that particular project file.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Correspondence or memoranda that do not pertain to documents
                having a prescribed retention period should generally be
                discarded sooner. These may be divided into two general
                categories:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Those pertaining to non-routine matters or having
                    significant lasting consequences should generally be
                    retained permanently.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
              start={2}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Those pertaining to routine matters and having no
                    significant, lasting consequences should be discarded within
                    five years. Some examples include:
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Routine letters and notes that require no acknowledgment or
                    follow up, such as notes of appreciation, congratulations,
                    letters of transmittal, and plans for meetings.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Form letters that require no follow up.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Letters of general inquiry and replies that complete a cycle
                    of correspondence.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Letters or complaints requesting specific action that have
                    no further value after changes are made or action taken
                    (such as name or address change).
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Other letters of inconsequential subject matter or that
                    definitely close correspondence to which no further
                    reference will be necessary.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Chronological correspondence files.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                  marginLeft: "36pt",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Please note that copies of interoffice correspondence and
                    documents where a copy will be in the originating department
                    file should be read and destroyed, unless that information
                    provides reference to or direction to other documents and
                    must be kept for project traceability.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                5. RETAINING PERSONAL INFORMATION
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    This Section sets out the data retention policies and
                    procedure of GK Telecom Limited, which are designed to help
                    ensure compliance with legal obligations in relation to the
                    retention and deletion of personal information
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Personal information that is processed by GK Telecom Limited
                    for any purpose or purposes shall not be kept for longer
                    than is necessary for that purpose or those purposes.
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",
                marginLeft: "7.653543307086615pt",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Without prejudice to point 2 (above) GK Telecom Limited will
                usually delete personal data falling within the categories set
                out below at the date/time set out below:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information about a computer and about visits to and use of
                    this website (including an IP address, geographical
                    location, browser type and version, operating system,
                    referral source, length of visit, page views and website
                    navigation paths: 2 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information provided when registering with our website
                    (including email address): 2 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information provided when completing a profile on our
                    website (including a name, gender, date of birth, interests
                    and hobbies, educational details): 2 years
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Information provided for subscribing to email notifications
                and/or newsletters (including a name and email address): &nbsp;
                &nbsp;Indefinitely or until the client chooses to ‘unsubscribe’
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information provided when using the services on the website,
                    or that is generated during the use of those services
                    (including the timing, frequency and pattern of service use)
                    : Indefinitely
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information relating to any subscriptions made (including
                    name, address, telephone number, email address and sector
                    sought): 2 years or until consent is withdrawn
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information posted to our website for publication on the
                    internet: 5 years after post
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Information contained in or relating to any communications
                    sent through the website (including the communication
                    content and meta data associated with the communication): 2
                    years following contact
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Any other personal information chosen to be sent: 2 years
                    following contact
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Notwithstanding the other provisions of this Section, GK Telecom
                Limited will retain documents (including electronic documents)
                containing personal data:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    to the extent that GK Telecom Limited is required to do so
                    by law;
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    if GK Telecom Limited believes that the documents may be
                    relevant to any ongoing or prospective legal proceedings;
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    and to establish, exercise or defend GK Telecom Limited’s
                    legal rights (including providing information to others for
                    the purposes of fraud prevention and reducing credit risk).
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    if explicit consent is given by the data subject. Consent is
                    requested at least every 2 years from candidates seeking
                    contract roles and at least every 12 months for candidates
                    seeking permanent employment.
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Each day GK Telecom Limited will run a database backup copy of
                all electronic data contained on GK Telecom Limited data centre,
                except for the one financial package. All other databases are in
                the cloud, connected to a Tier 3 data centre. This backup will
                include all information relating to current users, as well as
                any information that remains due to any reason contained in this
                policy.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                6. ELECTRONIC DOCUMENTS
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Electronic Mail: Not all email needs to be retained,
                    depending on the subject matter.
                  </span>
                </p>
                <ol
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: 48,
                  }}
                >
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                    aria-level={2}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",

                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        All e-mail—from internal or external sources – is to be
                        deleted after 12 months. Staff will strive to keep all
                        but an insignificant minority of their e-mail related to
                        business issues.
                      </span>
                    </p>
                  </li>
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                    aria-level={2}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",

                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        GK Telecom Limited will archive e-mail for 90 days after
                        the staff has deleted it, after which time the e-mail
                        will be permanently deleted.
                      </span>
                    </p>
                  </li>
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                    aria-level={2}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",

                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Staff will take care not to send
                        confidential/proprietary information held by GK Telecom
                        Limited to outside sources
                      </span>
                    </p>
                  </li>
                  <li
                    dir="ltr"
                    style={{
                      listStyleType: "lower-alpha",
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre",
                    }}
                    aria-level={2}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",

                        textAlign: "justify",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",

                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Any e-mail staff deems vital to the performance of their
                        job should be copied to the relevant client or candidate
                        record in GK Telecom Limited CRM system.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Electronic Documents: including Office 365 and PDF files,
                    retention also depends on the subject matter.
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                GK Telecom Limited does not automatically delete electronic
                files beyond the dates specified in this Policy. It is the
                responsibility of all staff to adhere to the guidelines
                specified in this policy.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In certain case’s a document will be maintained in both paper
                and electronic form. In such cases the official document will be
                the electronic document.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                7. INSURANCE RECORDS
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Certificates Issued to GK Telecom Limited Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Claims Files (including correspondence, medical records,
                    etc.) Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Insurance Policies (including expired policies) Permanent
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                8. LEGAL FILES AND PAPERS
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Legal Memoranda and Opinions (including all subject matter
                    files): 7 years after close of matter
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Litigation Files: 1 year after expiration of appeals or time
                    for filing appeals
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Court Orders: Permanent MISCELLANEOUS
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Material of Historical Value (including pictures, publications):
                &nbsp; &nbsp;Permanent
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Policy and Procedures Manuals – Original: Current version
                    with revision history
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Annual Reports: Permanent
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                10. PERSONNEL RECORDS
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Employee Personnel Records (including individual attendance
                    records, application forms, job or status change records,
                    performance evaluations, termination papers, withholding
                    information, garnishments, test results, training and
                    qualification records): 6 years after separation
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Employment Contracts – Individual: 6 years after separation
                    ▪ Employment Records Correspondence with Employment Agencies
                    and Advertisements for Job Openings: &nbsp; &nbsp;3 years
                    from date of hiring decision
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                11. TAX RECORDS
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                General Principle: GK Telecom Limited must keep books of account
                or records as are sufficient to establish amount of gross
                income, deductions, credits, or other matters required to be
                shown in any such return.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                These documents and records shall be kept for as long as the
                contents thereof may become material in the administration of
                state, and local income, franchise, and property tax laws.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Record Type and then Retention Period
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Tax-Exemption Documents and Related Correspondence: &nbsp;
                    &nbsp;Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Tax Bills, Receipts, Statements: 7 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Tax Returns: Permanent
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Sales/Use Tax Records: 7 years
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Annual Information Returns: Permanent
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>

            <hr />
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                At gktelecom.com, accessible from https://gktelecom.com/, one of
                our main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by gktelecom.com and how we use it.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collected in gktelecom.com.
                This policy is not applicable to any information collected
                offline or via channels other than this website.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Consent
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Information we collect
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                How we use your information
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We use the information we collect in various ways, including to:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Provide, operate, and maintain our website
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Improve, personalize, and expand our website
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Understand and analyze how you use our website
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Develop new products, services, features, and functionality
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Send you emails
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Find and prevent fraud
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Log Files
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                gktelecom.com follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services’ analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analysing trends, administering the site, tracking users’
                movement on the website, and gathering demographic information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Cookies and Web Beacons
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Like any other website, gktelecom.com uses ‘cookies’. These
                cookies are used to store information including visitors’
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimise the
                users’ experience by customizing our web page content based on
                visitors’ browser type and/or other information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                For more general information on cookies, please read the Cookies
                article on Generate Privacy Policy website.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Advertising Partners Privacy Policies
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of gktelecom.com.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on
                gktelecom.com, which are sent directly to users’ browsers. They
                automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their
                advertising campaigns and/or to personalise the advertising
                content that you see on websites that you visit.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Note that gktelecom.com has no access to or control over these
                cookies that are used by third-party advertisers.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Third Party Privacy Policies
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                gktelecom.com’s Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers’ respective websites.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                CCPA Privacy Rights (Do Not Sell My Personal Information)
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Under the CCPA, among other rights, California consumers have
                the right to:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Request that a business that collects a consumer’s personal
                    data disclose the categories and specific pieces of personal
                    data that a business has collected about consumers.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Request that a business delete any personal data about the
                    consumer that a business has collected.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Request that a business that sells a consumer’s personal
                    data, not sell the consumer’s personal data.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                GDPR Data Protection Rights
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to access – You have the right to request copies
                    of your personal data. We may charge you a small fee for
                    this service.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to rectification – You have the right to request
                    that we correct any information you believe is inaccurate.
                    You also have the right to request that we complete the
                    information you believe is incomplete.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to erasure – You have the right to request that we
                    erase your personal data, under certain conditions.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to restrict processing – You have the right to
                    request that we restrict the processing of your personal
                    data, under certain conditions.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to object to processing – You have the right to
                    object to our processing of your personal data, under
                    certain conditions.
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "decimal",
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={1}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.3800000000000001",

                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",

                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    The right to data portability – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 600,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Children’s Information
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                gktelecom.com does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: "1.3800000000000001",

                textAlign: "justify",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",

                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
