import axios from 'axios';
import { API_URL} from '../constant/Constant';


class PersonalInformationCreditCheckService{
    checkUserStatus(emailId) {
        //console.log("emailId service==>: ",emailId);
        return axios.get(`${API_URL}authentication/getCustomerVerificationStatus/${emailId}`,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
        });
    }

}
export default new PersonalInformationCreditCheckService()
