import axios from 'axios';

import React, { Component } from 'react';
import { API_URL } from '../constant/Constant';

class RegisterService {
    
    registerUser(json) {
        return axios.post(`${API_URL}authentication/signup`,json, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }

    registerDealerUser(json) {
        return axios.post(`${API_URL}authentication/signupDealer`,json, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

    }
    
}



export default new RegisterService()
