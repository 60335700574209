import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { RiWechatLine } from "react-icons/ri";
import logoWhiteBg from "../../assets/logo_white_bg.jpg";
import { useDispatch } from "react-redux";
import { openDocumentModal } from "../legal/documentModal/DocumentModalSlice";
import DocumentModal from "../legal/documentModal/DocumentModal";
import { openCookies } from "../cookies/cookieSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const userRoleId = localStorage.getItem("roleId") || "";
  const navigate = useNavigate();
  return (
    <>
      <footer className="mx-auto max-w-6xl text-center lg:text-left border-t mt-14">
        {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
        <div className="mx-6 pt-6 text-center md:text-left">
          <div className="grid grid-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            {/* <!-- TW Elements section --> */}
            <div className="flex gap-8 flex-col md:flex-row md:justify-between flex-wrap">
              <div>
                <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                  MENU
                </h6>
                <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                  <Link to="/" className="hover:opacity-50 transition">
                    Home
                  </Link>
                </p>
                {/* <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                  <a className="">Affiliate</a>
                </p> */}
                {/* <p className="mb-4 underline cursor-pointer">
                <a className="">Partner</a>
              </p> */}
                <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                  <Link to="/about-us" className="hover:opacity-50 transition">
                    About Us
                  </Link>
                </p>
                <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                  <Link to="/faq" className="hover:opacity-50 transition">
                    FAQ's
                  </Link>
                </p>
              </div>

              <div className="">
                <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                  LEGALS
                </h6>
                <p
                  onClick={() => dispatch(openDocumentModal(3))}
                  className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm hover:opacity-50 transition"
                >
                  Privacy Policy
                </p>
                {/* <p
                  onClick={() => dispatch(openDocumentModal(4))}
                  className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm"
                >
                  Terms & Conditions
                </p> */}
                {userRoleId === "ROLE_DEALER" ? (
                  <>
                    <p
                      onClick={() => dispatch(openDocumentModal(2))}
                      className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm hover:opacity-50 transition"
                    >
                      Three Mobile T&C
                    </p>
                    <p
                      onClick={() => dispatch(openDocumentModal(1))}
                      className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm hover:opacity-50 transition"
                    >
                      Partner C8 Info
                    </p>
                  </>
                ) : null}
                <p
                  onClick={() => dispatch(openCookies())}
                  className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm"
                >
                  Cookies Preferences
                </p>
              </div>
            </div>

            {/* <!-- Products section --> */}
            <div className="">
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                SIM Management
              </h6>
              <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                <Link
                  to="/sim-only-query"
                  className="hover:opacity-50 transition"
                >
                  SIM Only Query
                </Link>
              </p>
              <p className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm">
                <Link
                  to="/cancellation"
                  className="hover:opacity-50 transition"
                >
                  Cancellation
                </Link>
              </p>
              <p
                onClick={() => navigate("/complaint")}
                className="mb-4 underline decoration-1 underline-offset-2 cursor-pointer text-sm"
              >
                Complaints
              </p>
              <br />
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                COMPANY DETAILS
              </h6>
              <p className="mb-4 text-sm">Co. No: 7002607</p>
              <p className="mb-4 text-sm">VAT No: GB 322 637 908</p>
            </div>
            {/* <!-- Useful links section --> */}
            <div className="">
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                OFFICE ADDRESS
              </h6>
              <p className="mb-4 text-sm">
                Cranbourne House,
                <br/>
                 135 Bath Road, Slough SL1 3UX
              </p>
              <br />
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                REGISTERED ADDRESS
              </h6>
              <p className="mb-4 text-sm">
                GK Telecom Ltd,
                <br />
                Alpha House, 296 Kenton Road, Kenton, Harrow, Middlesex HA3 8DD
              </p>
            </div>
            {/* <!-- Contact section --> */}
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Contact
              </h6>
              <div className="mb-4 flex items-start justify-center md:justify-start">
                <div>
                  <MdOutlineMail className="mr-2 text-lg" />
                </div>
                <p className="leading-none text-sm">help@iotmobile.co.uk</p>
              </div>
              <div className="mb-4 flex items-start justify-center md:justify-start">
                <div>
                  <RiWechatLine className="mr-2 text-[20px]" x />
                </div>
                <p className="leading-none text-sm">
                  24/7 Stay connected through our dedicated email address or
                  conveniently reach out to us via our contact form
                </p>
              </div>
              {/* <div className="mb-4 flex items-start justify-center md:justify-start">
              <div>
                <FaWhatsapp className="mr-2 text-lg" />
              </div>
              <p className="leading-none">07842924458</p>
            </div> */}
            </div>
          </div>
        </div>

        {/* <!--Copyright section--> */}
        <div className="mt-3 p-5 text-center text-xs border-t border-gray-200">
          Copyright 2022 ©
          <a
            className="font-medium mx-2 underline decoration-1 underline-offset-2"
            href="https://gktelecom.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gk Telecom
          </a>
          | All rights Reserved.
        </div>
        <div className="hidden">
          <img src={logoWhiteBg} alt="" className="opacity-0" />
        </div>
      </footer>
      <DocumentModal
        position="center"
        title="Documentation"
        bottomLine={true}
      />
    </>
  );
};

export default Footer;
