import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./index.css";
import loadingGif from "./assets/loading.gif"
import Home from "./features/home/Home";
import Login from "./features/login/Login";
import Register from "./features/login/Register";
import ProductDetails from "./features/product-details/ProductDetails";
import Checkout from "./features/checkout/Checkout"
import Error404 from "./features/error/Error404";
import ResetPassword from "./features/login/ResetPassword";
import ForgotPassword from "./features/login/ForgotPassword";
import ChangePassword from "./features/login/ChangePassword";
import VerificationResponse from "./features/login/VerificationResponse";
import Loader from "./features/loader/Loader";
import VerificationOnly from "./features/login/VerificationOnly";
import MyOrder from "./features/order/MyOrder";
import Dashboard from "./features/dashboard/Dashboard";
import FreeAsYouGoSim from "./features/free-sim/FreeAsYouGoSim";
import FreeSimOrder from "./features/free-sim/FreeSimOrder";
import AcknowledgeContract from "./features/checkout/AcknowledgeContract";
import CheckoutDirectOrder from "./features/checkout/CheckoutDirectOrder";
import Cancellation from "./features/cancellation/Cancellation";
import CancellationConfirmation from "./features/cancellation/CancellationConfirmation";
import OrderCancellationStatus from "./features/cancellation/OrderCancellationStatus";
import PrivacyPolicy from "./features/legal/PrivacyPolicy";
import GKTTnC from "./features/legal/GKTTnC";
import ThreeTnC from "./features/legal/ThreeTnC";
import AboutUs from "./features/legal/AboutUs";
import FAQ from "./features/legal/FAQ";
import DocumentUpdates from "./features/legal/documentUpdates/DocumentUpdates";
import SignupContract from "./features/login/SignupContract";
import VerificationForUserUpdateReq from "./features/login/VerificationForUserUpdateReq";
import Complaint from "./features/complaint/Complaint";
import SimOnlyQuery from "./features/legal/SimOnlyQuery";
import IotDevices from "./features/legal/IotDevices";

const loading = () => (
  <Loader />
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Routes>
            <Route path="/" name="Home" element={<Home/>} />
            <Route path="/login" name="Login" element={<Login/>}/>
            <Route path="/register" name="Register" element={<Register />}/>
            <Route path="/productDetails" name="Product Details" element={<ProductDetails/>}/> 
            <Route path="/checkout" name="Checkout" element={<Checkout/>}/> 
            <Route path="/checkout" name="Checkout" element={<Checkout/>}/> 
            <Route path="/forgotPassword/:id" name="Forgot password" element={<ForgotPassword/>}/> 
            <Route path="/login/:id" name="login redirect" element={<VerificationResponse/>}/> 
            <Route path="/register/dealer/:id" name="login redirect" element={<VerificationResponse/>}/> 
            <Route path="/forgotPasswordVerify/:param1/:param2" name="Reset Password" element={<ResetPassword/>}/>
            <Route path="/changePassword" name="Change password" element={<ChangePassword/>}/> 
            <Route path="/verify-only/:id" name="verification only" element={<VerificationOnly />} />  
            <Route path="/myorder" name="My Order" element={<MyOrder />} />  
            <Route path="/dashboard" name="Dashboard" element={<Dashboard />} />  
            <Route path="/free-sim" name="Free pay as you go" element={<FreeAsYouGoSim />} />  
            <Route path="/acknowledgeContract/:id1/:id2" name="Acknowledge Contract page" element={<AcknowledgeContract />} />
            <Route path="/free-sim-order" name="Free sim order page" element={<FreeSimOrder />} />  
            <Route path="/free-sim-orderConfirmation" name="Free sim order confirmation page" element={<CheckoutDirectOrder />} />  
            <Route path="/free-sim-orderConfirmation/:id" name="Free sim order confirmation page" element={<CheckoutDirectOrder />} />
            <Route path="/cancellation" name="Cancellation" element={<Cancellation />} />  
            <Route path="/cancel-confirmation" name="Cancel Confirmation" element={<CancellationConfirmation />} />  
            <Route path="/order-cancellation/:verificationId/:orderId" name="Order Cancellation Status" element={<OrderCancellationStatus />} /> 
            <Route path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />  
            <Route path="/iot-terms-and-condition" name="GKT terms & condition" element={<GKTTnC />} />  
            <Route path="/three-terms-and-condition" name="Three terms & condition" element={<ThreeTnC />} />  
            <Route path="/about-us" name="About Us" element={<AboutUs />} />  
            <Route path="/faq" name="Frequently Asked Question" element={<FAQ />} />  
            <Route path="/updatedDocument" name="Updated Document" element={<DocumentUpdates />} />  
            <Route path="/signupContract/:id" name="signup contract" element={<SignupContract/>}/> 
            <Route path="/complaint" name="complaint" element={<Complaint/>}/> 
            <Route path="/sim-only-query" name="SIMOnly query" element={<SimOnlyQuery/>}/> 
            <Route path="/acknowledgementFirstAndLastName/:id" name=" Update user req verification" element={<VerificationForUserUpdateReq />} />  
            <Route path="/signupContract/:id" name="signup contract" element={<SignupContract/>}/> 
            <Route path="/iot-devices" name="IOT Devices" element={<IotDevices/>}/> 
            <Route path="/register/:id" name="Customer register" element={<Register/>}/> 
            <Route path="/:referralCode" name="Referral code" element={<Home/>}/> 
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;