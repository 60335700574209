import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useState } from "react";
import { MdLocationOn, MdOutlineSimCard } from "react-icons/md";
import { BiCopy } from "react-icons/bi";
import LoaderButton from "../button/LoaderButton";
import useScrollToTop from "../customHooks/useScrollToTop";
import ModalComponent from "../modalComponent/ModalComponent";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../modalComponent/modalSlice";
import Button from "../button/Button";
import { Link, useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import AddressService from "../../services/AddressService";
import axios from "axios";
import { API_URL } from "../../constant/Constant";
import CustomSelect from "../common/dropdown/CustomSelect";
import OrderService from "../../services/OrderService";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";

//model

const ModalForDirectOrderLimitExceed = ({ navigate, dispatch }) => {
  return (
    <div>
      <div>
        <p>
          We’re sorry to inform you that the maximum free SIM order limit has
          been reached, and we are unable to process this order.
        </p>
      </div>
      <div className="w-full flex justify-center items-center">
        <Button
          handleClick={() => {
            dispatch(closeModal("direct-order-limit-exceeded"));
            navigate("/");
           
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          title="Go to Homepage"
          type="button"
          style="cta-two"
        />
      </div>
    </div>
  );
};

const ModalForIccid = ({
  resendBtnDisabled,
  handleSubmitButton,
  propsError,
}) => {
  const [iccid, setIccid] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (iccidResult) => {
    console.log("handleSubmit", iccidResult);
    setError("");
    let tempError = "";

    // Validation for ICCID
    if (!iccidResult || iccidResult <= 0) {
      tempError = "Please enter a valid ICCID";
    } else if (iccidResult.length !== 19) {
      tempError = "ICCID must be exactly 19 digits";
    }

    if (tempError) {
      setError(tempError);
    } else {
      setError("");
      handleSubmitButton(iccidResult); // Call the function with the valid ICCID
    }
  };

  return (
    <div>
      <h2 className="text-md font-semibold mb-2">
        Please enter the ICCID to proceed further.
      </h2>
      <div className="w-full mt-4">
        <input
          value={iccid}
          onChange={(e) => {
            setIccid(e.target.value.trim());
            if (error) {
              setError("");
            }
          }}
          type="number"
          id="iccid"
          placeholder="Please enter ICCID number"
          className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
        />

        {error && (
          <div className="h-5 mt-3">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
          )}
          {!error && propsError && (
          <div className="h-5 mt-3">
            <p className="text-red-600 text-sm">{propsError?.checkout}</p>
          </div>
        )}
            
        

        <LoaderButton
          title="Submit"
          type="button"
          disabled={resendBtnDisabled}
          handleClick={() => handleSubmit(iccid)} // Fix: pass the handleSubmit as a callback
          style="w-full px-4 py-2 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        />
      </div>
    </div>
  );
};

const FreeSimOrder = () => {
  useScrollToTop();
  //states
  const [orderTypeActive, setOrderTypeActive] = useState("free");
  const dispatch = useDispatch();
  const [creditCheck, setCreditCheck] = useState(
    JSON.parse(localStorage.getItem("creditCheck"))
      ? JSON.parse(localStorage.getItem("creditCheck"))
      : {}
  );
  const [order, setOrder] = useState(
    JSON.parse(localStorage.getItem("order"))
      ? JSON.parse(localStorage.getItem("order"))
      : {}
  );
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const modalInitState = useSelector((state) => state.modal);
  console.log("modalInitState",modalInitState);

  // shipping add
  const [shippingZipCode, setShippingZipCode] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [shippingAddValue, setShippingAddValue] = useState(null);
  const [shippingPostCode, setShippingPostCode] = useState(null);
  const [shippingAddressKey, setShippingAddressKey] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [shippingBtnDisable, setShippingBtnDisable] = useState(false);
  const [editAddressFlag, setEditAddressFlag] = useState(false);
  const [suggestedAddDropdown, setSuggestedAddDropdown] = useState(false);

  const [iccidValue, setIccidValue] = useState(null);
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);
  const [deliveryMethodForRadioButton, setDeliveryMethodForRadioButton] =
    useState("");

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));

  const handleRadioChange = () => {
    if(userRoleId === "ROLE_DEALER"){
    const selectedMethodElement = document.querySelector(
      'input[name="deliveryMethod"]:checked'
    );
    console.log("selectedMethodElement", selectedMethodElement);

    // Check if any radio button is selected
    if (!selectedMethodElement) {
      setErrors((prev) => ({
        ...prev,
        deliveryMethodForRadioButton: "Please select a delivery method.",
      }));
      return;
    } 
    setErrors((prev) => ({
      ...prev,
      deliveryMethodForRadioButton: "",
    }));

    const selectedMethodName = selectedMethodElement.value;
    console.log("selectedMethodName", selectedMethodName);
    let backendValue;
    if (selectedMethodName === "collectAtStore") {
      backendValue = 1;
      // setSelectedMethod(backendValue);
      dispatch(openModal("iccidInput"));
    } else if (selectedMethodName === "dispatchFromOffice") {
      console.log("222");
      backendValue = 2;
      // setSelectedMethod(backendValue);
      handleSubmitButton(null);
    }
    setSelectedMethod(backendValue);
    // setErrors((prev) => ({ ...prev, deliveryMethodForRadioButton: "" }));
  }else{
    handleSubmitButton(null);
  }
  };
  console.log("selectedMethod", selectedMethod);

  //use effects
  const handleOrderType = (type) => {
    if (type === "free") {
      setOrderTypeActive("free");
    } else if (type === "add-on") {
      setOrderTypeActive("add-on");
    }
  };
  // useEffect(() => {
  //   dispatch(openModal("free-sim-checkout"));
  // }, []);

  useEffect(() => {
    fetchDeliveryStatus();
    setShippingZipCode(creditCheck.shippingAddress?.postCode);
    setShippingPostCode(creditCheck.shippingAddress?.postCode);
    setShippingAddValue(creditCheck.shippingAddress?.addressStr);
    setShippingAddressKey(creditCheck.shippingAddress?.addressKey);
    setDeliveryMethod(
      creditCheck.deliveryMethod?.deliveryMethodId > 0
        ? creditCheck.deliveryMethod?.deliveryMethodId
        : null
    );
  }, []);

  useEffect(() => {
    setCreditCheck((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        postCode: shippingPostCode,
        addressStr: shippingAddValue,
        addressKey: shippingAddressKey,
      },
      deliveryMethod: {
        ...prev.deliveryMethod,
        deliveryMethodId: deliveryMethod,
      },
    }));
  }, [shippingPostCode, shippingAddValue, shippingAddressKey, deliveryMethod]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      shippingZipCode: null,
    }));
  }, [shippingZipCode]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      shippingAddValue: null,
    }));
  }, [shippingAddValue]);

  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      deliveryMethod: null,
    }));
  }, [deliveryMethod]);

  useEffect(() => {
    if (shippingAddress && shippingAddValue) {
      let adress = shippingAddress.filter((addr) => {
        return addr.addressStr === shippingAddValue;
      });
      if (adress.length > 0) {
        setShippingPostCode(adress[0].postCode);
        setShippingAddressKey(adress[0].addressKey);
      }
    }
  }, [shippingAddValue]);

  //funcitons
  const handleShippingZipCodeSearch = () => {
    setShippingAddValue(null);
    setShippingAddress([]);
    setErrors({});

    if (shippingZipCode && shippingZipCode.trim().length > 0) {
      const zipCodeRegex = /^[a-zA-Z0-9\s]{6,8}$/;
  
      if (!zipCodeRegex.test(shippingZipCode)) {
        setErrors((prev) => ({
          ...prev,
          shippingZipCode: "Invalid Post Code format",
        }));
        return; 
      }
      setShippingBtnDisable(true);
      // call Api here
      AddressService.addressApi(shippingZipCode)
        .then((response) => {
          if (response.status == 200) {
            setShippingAddress(response.data);
            setShippingBtnDisable(false);
            setSuggestedAddDropdown(true);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setErrors((prev) => ({
              ...prev,
              shippingZipCode: "Please enter a complete post code",
            }));
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                break;
              case 403:
                break;
              case 500:
                break;
              case 404:
                break;
              case 406:
                break;
              case 412:
                break;
              default:
                break;
            }
            setErrors((prev) => ({
              ...prev,
              shippingZipCode: "Please try with a different  post code",
            }));
          }
          setShippingBtnDisable(false);
        });

      // setTimeout(() => {
      //   setShippingAddress(true);
      //   setShippingBtnDisable(false);
      // }, 2000);
    } else {
      setErrors((prev) => ({
        ...prev,
        shippingZipCode: "Please enter Post code",
      }));
    }
  };
  console.log("userRoleId", userRoleId);
  const userId = localStorage.getItem("userId");
  //Insert the address
  const handleSubmitButton = (iccid) => {
    setResendBtnDisabled(true);

    setLoader(true);
    setErrors({});
    let tempError = {};

    //add validations
    if (
      shippingZipCode === "null" ||
      shippingZipCode === null ||
      shippingZipCode === undefined ||
      shippingZipCode.trim().length === 0
    ) {
      tempError.shippingZipCode = "Please search the Residential Address";
    }
    if (
      shippingAddValue === "null" ||
      shippingAddValue === null ||
      shippingAddValue === undefined ||
      shippingAddValue.trim().length === 0
    ) {
      tempError.shippingAddValue = "Please select Residential Address";
    }
    if (!deliveryMethod || deliveryMethod <= 0) {
      tempError.deliveryMethod = "Please select Address Type";
    }

    // if (!selectedMethod) {
    //   tempError.deliveryMethodForRadioButton =
    //     "Please select a delivery method";
    // }

    //if validation failed then return
    if (Object.keys(tempError).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...tempError,
      }));
      setLoader(false);
      return;
    }
    console.log("creditCheck", creditCheck);
    setIccidValue(iccid);
    const updatedCreditCheck = {
      ...creditCheck,
      iccid: iccid,
      createdBy: { id: userId },
    };
    setCreditCheck(updatedCreditCheck);
    const updatedOrder = {
      ...order,
      deliveryType: {
        ...order.deliveryType,
        deliveryTypeId: selectedMethod,
      },
      creditCheck: { ...updatedCreditCheck },
    };
    setOrder(updatedOrder);
    console.log("selectedMethod", selectedMethod);

    console.log("updatedOrder", updatedOrder);

    //call order api
    OrderService.createOrderForPayAsYouGo(updatedOrder)
      .then((response) => {
        if (response.status == 200) {
          setResendBtnDisabled(false);
          dispatch(closeModal("iccidInput"));
          //if successful then redirect to order confirmation page with order details
          let tempOrder = response.data;
          tempOrder.orderId = undefined;
          localStorage.setItem("orderConfirmation", JSON.stringify(tempOrder));
          //console.log("Order confirmation", response.data);
          // Cookies.remove("accesstoken");
          if(userRoleId === "ROLE_CUSTOMER"){
            navigate("/free-sim-orderConfirmation");
          }else{
            navigate("/free-sim-orderConfirmation");
          }
         if(userRoleId === "ROLE_DEALER"){
          if (selectedMethod == 1){
            navigate(`/free-sim-orderConfirmation/${selectedMethod}`)
          } else{
            navigate("/free-sim-orderConfirmation");
          }
        }
          setLoader(false);
        }
      })
      .catch((error) => {
        // dispatch(closeModal("iccidInput"));
        setResendBtnDisabled(false);
        if (error.message === "Network Error") {
          setErrors((prev) => ({
            ...prev,
            checkout: "Network error occurred. Please try again later.",
          }));
          setLoader(false);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 403:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 500:
              setErrors((prev) => ({
                ...prev,
                checkout:
                  "Internal server error occurred. Please try again later.",
              }));
              break;
            case 404:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            case 406:
              if (
                error.response.data.failedReason ===
                "Pay as you go order limit exeeded"
              ) {
                dispatch(openModal("direct-order-limit-exceeded"));
              } else {
                setErrors((prev) => ({
                  ...prev,
                  checkout: error.response.data.failedReason,
                }));
              }
              break;
            case 412:
              setErrors((prev) => ({
                ...prev,
                checkout: error.response.data.failedReason,
              }));
              break;
            default:
              setErrors((prev) => ({
                ...prev,
                checkout: "Unknown error occurred. Please try again later.",
              }));
              break;
          }
          setLoader(false);
        }
        setShippingBtnDisable(false);
      });
  };

  const fetchDeliveryStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}api/v1/deliveryMethod`);
      if (response.status === 200) {
        setDeliveryStatuses(response.data);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        errors.stepOne = "Network error occurred. Please try again later.";
      } else {
        switch (error.response ? error.response.status : "") {
          case 400:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
          case 403:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
          case 404:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
          case 406:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
          case 500:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              deliveryMethod:
                "Failed to fetch Address Type options. Please try again later.",
            }));
            break;
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-3">
        <div className="max-w-5xl mx-auto flex flex-col justify-center items-center h-auto">
          <h1 className="font-semibold text-2xl text-center">
            Order a Free SIM Card
          </h1>
          <p className="font-normal text-[14px] text-center">
            Enter your details and we'll send you a free SIM
          </p>
        </div>
        {/* tabs */}
        <div className="border-b mt-4">
          <div className="max-w-xl mx-auto w-full flex justify-center items-center">
            <button
              onClick={() => handleOrderType("free")}
              className={`w-full h-14 relative ${
                orderTypeActive == "free" ? "tab-active" : "opacity-70"
              }`}
            >
              Free SIM
            </button>
            <button
              disabled={true}
              // onClick={() => handleOrderType("add-on")}
              className={`w-full h-14 relative ${
                orderTypeActive == "add-on" ? "tab-active" : "opacity-70"
              }`}
            >
              Add on's
            </button>
          </div>
        </div>
        <div className="max-w-2xl mx-auto mt-4 flex flex-col justify-center items-center h-auto">
          {orderTypeActive == "free" ? (
            <form className="w-full mb-14">
              <p className="font-medium mb-3">1 item selected</p>
              <div className="rounded-lg flex items-start mb-4 p-3 custom-border bg-[#5A949A]/15 border border-[#5A949A]">
                <MdOutlineSimCard className="text-3xl mt-1 mr-3" />
                <div>
                  <h2 className="text-xl font-medium mb-1">
                    Pay as you go SIM
                  </h2>
                  <div className="flex items-center cursor-pointer">
                    <p className="underline text-sm">
                      Plan and pricing details
                    </p>
                    <BiCopy className="ml-2" />
                  </div>
                </div>
              </div>

              <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                <h2 className="text-xl font-semibold leading-8">
                  Order Details
                </h2>
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="mt-4">
                    <div className="flex-col-2">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 "
                      >
                        First Name*
                      </label>
                      <div className="mt-2">
                        <input
                          value={creditCheck.firstName}
                          //onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                          id="first-name"
                          //placeholder="Please enter your first name"
                          className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          readOnly
                        />
                      </div>
                      {/* {errors?.firstNameValue && (
                                <p className="error-text">
                                  {errors.firstNameValue}
                                </p>
                              )} */}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex-col-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 "
                      >
                        Last Name*
                      </label>
                      <div className="mt-2">
                        <input
                          value={creditCheck.lastName}
                          //onChange={(e) => setLastName(e.target.value)}
                          //placeholder="Please enter your last name"
                          type="text"
                          id="last-name"
                          className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          readOnly
                        />
                      </div>
                      {/* {errors?.lastNameValue && (
                                <p className="error-text">
                                  {errors.lastNameValue}
                                </p>
                              )} */}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 "
                    >
                      Email Id*
                    </label>
                    <div className="mt-2">
                      <input
                        value={creditCheck.emailId}
                        //onChange={(e) => setEmailId(e.target.value)}
                        id="email"
                        type="email"
                        //placeholder="Please enter your email address"
                        className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                        readOnly
                      />
                    </div>
                    {/* {errors?.emailValue && (
                              <p className="error-text">
                                {errors.emailValue}
                              </p>
                            )} */}
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium leading-6 "
                    >
                      Contact Number*
                    </label>
                    <div className="mt-2">
                      <input
                        value={creditCheck.contactNumber}
                        //onChange={(e) => setContactNumber(e.target.value)}
                        //placeholder="Please enter your contact number"
                        id="number"
                        type="text"
                        className="readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                        readOnly
                      />
                    </div>
                    {/* {errors?.contactNoValue && (
                              <p className="error-text">
                                {errors.contactNoValue}
                              </p>
                            )} */}
                  </div>
                </div>
                {userRoleId === "ROLE_DEALER" && (
                  <div className="mt-6 flex items-center space-x-4">
                    <div className="flex items-center">
                      <input
                        id="collectAtStore"
                        name="deliveryMethod"
                        type="radio"
                        value="collectAtStore"
                        className="h-4 w-4 text-teal-600 border-gray-300 focus:ring-teal-600"
                        onChange={() => setSelectedMethod(1)}
                      />
                      <label
                        htmlFor="collectAtStore"
                        className="ml-2 block text-sm font-medium leading-6"
                      >
                        Collect at Store
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="dispatchFromOffice"
                        name="deliveryMethod"
                        type="radio"
                        value="dispatchFromOffice"
                        className="h-4 w-4 text-teal-600 border-gray-300 focus:ring-teal-600"
                        onChange={() => setSelectedMethod(2)}
                      />
                      <label
                        htmlFor="dispatchFromOffice"
                        className="ml-2 block text-sm font-medium leading-6"
                      >
                        Dispatch from Office
                      </label>
                    </div>
                  </div>
                )}
                {errors.deliveryMethodForRadioButton && (
                  <p className="error-text">
                    {errors.deliveryMethodForRadioButton}
                  </p>
                )}
              </fieldset>
              {selectedMethod !== 1 && (
              <fieldset className="border custom-border-50 rounded-lg p-4 w-full mb-4">
                <h2 className="text-xl font-semibold leading-8">
                  Residential Address
                </h2>
                <div className="grid grid-cols-6 gap-4">
                  {editAddressFlag && (
                    <>
                      <div className="lg:col-span-4 md:col-span-3 col-span-full mt-4 relative">
                        <label
                          htmlFor="shipping-zip-search"
                          className="block text-sm font-medium leading-6"
                        >
                          Search by Post code
                        </label>
                        <div className="mt-2">
                          <input
                            value={shippingZipCode ? shippingZipCode : ""}
                            // onChange={(e) => {
                            //    setZipCode(e.target.value);
                            //  }}
                            type="text"
                            id="shipping-zip-search"
                            onChange={(e) => setShippingZipCode(e.target.value)}
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                          />
                        </div>
                        {errors?.shippingZipCode && (
                          <p className="error-text">{errors.shippingZipCode}</p>
                        )}
                      </div>
                      <div className="lg:col-span-2 md:col-span-3 col-span-full mt-4 relative">
                        <LoaderButton
                          type="button"
                          title="Search address"
                          style="md:mt-[32px]"
                          handleClick={handleShippingZipCodeSearch}
                          disabled={shippingBtnDisable}
                        />
                      </div>
                    </>
                  )}
                  {editAddressFlag &&
                    shippingAddress &&
                    suggestedAddDropdown && (
                      <div className="col-span-full mt-4">
                        <label
                          htmlFor="suggested-address"
                          className="block text-sm font-medium leading-6"
                        >
                          Suggested Addresses
                        </label>
                        <div className="mt-2">
                          <CustomSelect
                            value={shippingAddValue}
                            options={shippingAddress.map((e) => e.addressStr)}
                            placeholder="Choose an address"
                            onChange={setShippingAddValue}
                            //onChange={(e) => handleShippingAddSelection(e.target.value)}
                            icon={
                              <MdLocationOn className="text-lg opacity-40 mr-1" />
                            }
                          />
                        </div>
                        {errors?.shippingAddValue && (
                          <p className="error-text">
                            {errors.shippingAddValue}
                          </p>
                        )}
                      </div>
                    )}

                  {/* {shippingAddValue && ( */}
                  {shippingAddValue && (
                    <div className="col-span-full mt-6 w-full">
                      <p className="font-semibold text-lg mb-2 border-b pb-3 flex justify-between items-center">
                        <span>Deliver to</span>
                        <span className="error-text cursor-pointer flex items-center">
                          <FaPen className="mr-1" />
                          <span
                            className="underline"
                            onClick={() => {
                              setEditAddressFlag(true);
                              setShippingAddValue(null);
                              setShippingZipCode(null);
                              setDeliveryMethod(null);
                              setShippingAddress([]);
                            }}
                          >
                            Edit Address
                          </span>
                        </span>
                      </p>
                      <p className="capitalize text-lg">
                        {shippingAddValue?.split(",").map((line, idx) => (
                          <span key={idx} style={{ display: "block" }}>
                            {line.trim()}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                  {shippingAddValue && (
                    <>
                      <div className="mt-4 lg:col-span-3 col-span-full relative">
                        <label
                          htmlFor="postal-code"
                          className="block text-sm font-medium leading-6 "
                        >
                          {/* ZIP / Postal code* */}
                          Post code*
                        </label>
                        <div className="mt-2">
                          <input
                            value={shippingPostCode}
                            type="text"
                            id="postal-code"
                            autoComplete="address-level1"
                            className="block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                          />
                        </div>
                      </div>
                      <div className="mt-4 relative lg:col-span-3 col-span-full">
                        <label
                          // htmlFor="country"
                          className="block text-sm font-medium leading-6"
                        >
                          Country*
                        </label>
                        <div className="mt-2">
                          <input
                            value="United Kingdom"
                            className="pointer-events-none block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 py-1.5 px-3 bg-transparent sm:text-sm sm:leading-6 readonly-field"
                          />
                        </div>
                      </div>
                      <div className="mt-4 lg:col-span-3 col-span-full">
                        <label
                          htmlFor="delivery-method"
                          className="block text-sm font-medium leading-6 "
                        >
                          Address Type*
                        </label>
                        <div className="mt-2">
                          <select
                            id="delivery-method"
                            name="delivery-method"
                            value={deliveryMethod || ""}
                            onChange={(e) => setDeliveryMethod(e.target.value)}
                            className={
                              editAddressFlag
                                ? "block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                                : "readonly-field block w-full rounded-md border custom-border-50 focus:border-1 focus:bg-custom-blue placeholder-teal-800 placeholder-opacity-50 focus:ring-1 focus:ring-teal-600 px-3 py-1.5 bg-transparent sm:text-sm sm:leading-6"
                            }
                          >
                            <option value="" disabled>
                              Select Address Type
                            </option>
                            {/* <option value={1}>Home</option>
                                    <option value={2}>Retail Store</option>
                                    <option value={3}>PUDO Store</option>
                                    <option value={4}>Work</option>
                                    <option value={5}>Other</option> */}
                            {deliveryStatuses.map((status) => (
                              <option
                                key={status.deliveryMethodId}
                                value={status.deliveryMethodId}
                              >
                                {status.deliveryMethodDesc}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors?.deliveryMethod && (
                          <p className="error-text">{errors.deliveryMethod}</p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </fieldset>
              )}

              <div className="flex items-center justify-center gap-3">
                {/* <p className="error-text text-center">{errors.checkout}</p> */}
                <LoaderButton
                  type="button"
                  title="Checkout"
                  handleClick={handleRadioChange}
                  style="cta-one px-3 py-2 w-1/2 rounded-lg"
                  // disabled={}
                />
              </div>
            </form>
          ) : orderTypeActive == "add-on" ? (
            <div>Add ON</div>
          ) : null}
        </div>
      </main>
      <Footer />
      {/* <ModalComponent
        size="md"
        type={"error"}
        position="center"
        title={"Order Limit Reached"}
        description=""
      >
        <div>
          <p>
            We’re sorry to inform you that the maximum free SIM order limit has
            been reached, and we are unable to process this order.
          </p>
        </div>
        <div className="w-full flex justify-center items-center">
          <Button
            handleClick={() => {
              navigate("/");
              dispatch(closeModal());
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            title="Go to Homepage"
            type="button"
            style="cta-two"
          />
        </div>
      </ModalComponent> */}

      <ModalComponent
        size={
          modalInitState.contentId === "direct-order-limit-exceeded"
            ? "md"
            : modalInitState.contentId === "iccidInput"
            ? "md"
            : null
        }
        type={
          modalInitState.contentId === "direct-order-limit-exceeded"
            ? "error"
            : modalInitState.contentId === "iccidInput"
            ? "info"
            : null
        }
        position={
          modalInitState.contentId === "direct-order-limit-exceeded"
            ? "center"
            : modalInitState.contentId === "iccidInput"
            ? "center"
            : null
        }
        title={
          modalInitState.contentId === "direct-order-limit-exceeded"
            ? "Order Limit Reached"
            : modalInitState.contentId === "iccidInput"
            ? "Please Enter ICCID"
            : null
        }
        description=""
        bottomLine={true}
      >
        {modalInitState.contentId === "direct-order-limit-exceeded" ? (
          <ModalForDirectOrderLimitExceed
            navigate={navigate}
            dispatch={dispatch}
          />
        ) : modalInitState.contentId === "iccidInput" ? (
          <ModalForIccid
            handleSubmitButton={handleSubmitButton}
            resendBtnDisabled={resendBtnDisabled}
            propsError={errors}
          />
        ) : null}
      </ModalComponent>
      {loader && <Loader />}
    </>
  );
};

export default FreeSimOrder;
