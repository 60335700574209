import React, { useEffect, useRef, useState } from "react";
import brand2 from "../../assets/brand-logo/giffgaff_logo.png";
import brand3 from "../../assets/brand-logo/gkt_logo.png";
import brand5 from "../../assets/brand-logo/O2_logo.png";
import brand4 from "../../assets/brand-logo/talkMobile_logo.png";
import brand1 from "../../assets/brand-logo/three_logo.png";
import brand6 from "../../assets/brand-logo/vodafone_logo.png";
import { RiDiscountPercentLine } from "react-icons/ri";
import { IoMdInformationCircleOutline } from "react-icons/io";

const ProductCard = ({ data, brandIndex, handleBuyNow }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const brands = [
    {
      id: 0,
      img: brand1,
    },
    {
      id: 1,
      img: brand2,
    },
    {
      id: 2,
      img: brand3,
    },
    {
      id: 3,
      img: brand4,
    },
    {
      id: 4,
      img: brand5,
    },
    {
      id: 5,
      img: brand6,
    },
    {
      id: 6,
      img: brand2,
    },
    {
      id: 7,
      img: brand3,
    },
  ];

  const handleTooltipClick = () => {
    setTooltipVisible((prev) => !prev);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
  //       setTooltipVisible(false); // Close the tooltip if clicked outside
  //     }
  //   };

  //   if (tooltipVisible) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [tooltipVisible]);

  return (
    <>
      <div className="flex flex-col border custom-border-50 rounded-lg p-4 relative">
        {data.planInfo && (
          <div
            style={{ borderRadius: "2px 7px 2px 15px" }}
            className="transition-all hover:bg-[#388087b2]/50 cursor-pointer absolute top-0 right-0 h-7 bg-[#388087b2] drop-shadow-lg px-2 z-10 flex justify-center items-center"
          >
            <div
              className="flex justify-center items-center gap-x-1 h-full"
              // onClick={handleTooltipClick}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <IoMdInformationCircleOutline className="text-md text-white" />
              <p className="text-white text-sm font-normal">Details</p>
            </div>
            {tooltipVisible && (
              <div
                className={`${
                  tooltipVisible ? "opacity-100" : "opacity-0"
                } transition-all absolute top-9 right-2 ml-4 min-w-64 max-w-72 bg-white hover:opacity-100 text-cta shadow-lg p-3 rounded-lg border border-cyan-600 max-h-48 overflow-y-auto`}
              >
                <p className="font-semibold">{data.planInfo}</p>
              </div>
            )}
          </div>
        )}
        <div className={`grid grid-cols-3 mt-4`}>
          <div className="flex flex-col justify-center items-center">
            {/* <h3 className="text-lg font-semibold">{data.data} GB</h3> */}
            <h3 className="text-lg font-semibold">
              {data.data === -1 ? "Unlimited" : data.data}{" "}
              {data.data === -1 ? "" : "GB"}
            </h3>
            <p>Data</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center middle-box">
            <h3 className="text-lg font-semibold">
              {" "}
              {data.noOfMinutes === -1 ? "Unlimited" : data.noOfMinutes}
            </h3>
            <p>Minutes</p>
          </div>
          {/* <div className="break-line"></div> */}
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-lg font-semibold">
              {data.noOfText === -1 ? "Unlimited" : data.noOfText}
            </h3>
            <p>Text</p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4">
          <div className="flex flex-col justify-center items-center">
            <img
              className="h-10 md:h-12 w-auto"
              src={brands[brandIndex].img}
              alt="network 3"
            />
            {/* <p className="font-semibold">Data</p> */}
          </div>
          <div className="flex flex-col justify-center items-center middle-box">
            <h4 className="text-md font-semibold">
              {data.contractTerm} Months
            </h4>
            <p>Duration</p>
          </div>
          <div className="flex flex-col justify-center items-center relative">
            <div className="relative flex justify-center items-center">
              <h4 className="text-lg font-semibold">£{data.finalPrice}</h4>
              {/* outline-1 outline-red-500/70 outline-dashed */}
              <span className="absolute top-0 -right-12 w-[35px] h-[35px] text-[0.4rem] p-1 md:w-[40px] md:h-[40px] md:text-[0.6rem] md:p-2 leading-none  font-medium bg-red-500/70 text-white border rounded-full flex flex-col justify-center items-center border-dashed rotate-12">
                <span className="text-[0.7rem] font-semibold">
                  {Math.ceil(data.discountPercent)}%
                </span>
                <span>OFF</span>
              </span>
            </div>
            <div className="flex justify-center items-center">
              <p className="text-md strikethrough px-1">
                £{data.tariffRecurringCharge}
              </p>
            </div>
          </div>
        </div>

        {handleBuyNow && (
          <div className="w-full flex justify-center items-center mt-4">
            {/* <Link className="w-1/2" to="/productDetails"> */}
            <button
              className="w-full cta-one py-2 px-4 rounded-md"
              onClick={() => handleBuyNow(data)}
            >
              Buy Now
            </button>
            {/* </Link> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductCard;
